import { Clipboard } from '@hexa-ui/icons';
import { useSidebar } from 'admin-portal-shared-services';
import useBeesProvider from 'context/BeesContext/BeesContext';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

const useSidebarForce = () => {
  const { formatMessage } = useIntl();
  const { country } = useBeesProvider();

  const FORCE_TASK_MANAGEMENT = {
    id: 'force-task-management',
    title: formatMessage({ id: 'useSidebarForce.Home' }),
    path: '/frontline/tasks',
    icon: () => <Clipboard />
  };

  const items = useMemo(() => {
    return [FORCE_TASK_MANAGEMENT];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useSidebar({
    items,
    utils: []
  });

  return items;
};

export default useSidebarForce;
