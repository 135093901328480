import { useIntl } from 'react-intl';
export const useDashboardIntl = () => {
  const { formatMessage } = useIntl();

  const titlePage = formatMessage({ id: 'audience.listPage.audience' });
  const loading = formatMessage({ id: 'audience.listPage.loading' });
  const labelNewAudienceBtn = formatMessage({ id: 'audience.listPage.addNew' });
  const labelOpenExportedFilesBtn = formatMessage({
    id: 'audience.listPage.openExportedFiles'
  });
  const searchPlaceholder = formatMessage({ id: 'audience.listPage.searchPlaceholder' });

  const handlePageSizeOptionsIntl = (value: number) =>
    formatMessage({ id: 'audience.listPage.itemsPerPage' }, { value });

  const noAudiencesDesc = formatMessage({ id: 'audience.listPage.noAudiences.description' });
  const noAudiencesBtn = formatMessage({
    id: 'audience.listPage.noAudiences.btnLabel'
  });

  const noAudiences = { noAudiencesDesc, noAudiencesBtn };

  const errorGetAudiencesDesc = formatMessage({
    id: 'audience.listPage.errorGetAudiences.description'
  });
  const errorGetAudiencesBtn = formatMessage({
    id: 'audience.listPage.errorGetAudiences.btnLabel'
  });

  const errorGetAudiences = { errorGetAudiencesDesc, errorGetAudiencesBtn };

  const errorSearchingAudiencesDesc = formatMessage({
    id: 'audience.listPage.errorSearchingAudiences.description'
  });
  const errorSearchingAudiencesBtn = formatMessage({
    id: 'audience.listPage.errorSearchingAudiences.btnLabel'
  });

  const errorSearchingAudiences = {
    errorSearchingAudiencesDesc,
    errorSearchingAudiencesBtn
  };

  const title = formatMessage({ id: 'audience.deleteAudienceModal.title' });
  const buttonDelete = formatMessage({ id: 'audience.deleteAudienceModal.buttonDelete' });
  const buttonCancel = formatMessage({ id: 'audience.deleteAudienceModal.buttonCancel' });
  const contentLinked = formatMessage({ id: 'audience.deleteAudienceModal.linked' });
  const contentDefault = formatMessage({ id: 'audience.deleteAudienceModal.default' });

  const modalDelete = { title, buttonCancel, buttonDelete, contentLinked, contentDefault };

  return {
    titlePage,
    labelNewAudienceBtn,
    labelOpenExportedFilesBtn,
    searchPlaceholder,
    handlePageSizeOptionsIntl,
    noAudiences,
    errorGetAudiences,
    errorSearchingAudiences,
    modalDelete,
    loading
  };
};
