import { Currency, Users } from '@hexa-ui/icons';
import { SidebarItem, useAuthenticationService, useSidebar } from 'admin-portal-shared-services';

import { useIntl } from 'react-intl';
import { PREFIXES, SIDEBAR_ITEMS } from './useMembershipScopeSidebar.types';
import { useCallback, useMemo } from 'react';

export const IconEarningRules = (): React.ReactElement => <Currency />;
export const IconUsers = (): React.ReactElement => <Users />;

const useMembershipScopeSidebar = (name: string) => {
  const authentication = useAuthenticationService();
  const userScopes = useMemo(() => authentication.parseJwt()?.scopes ?? [], [authentication]);
  const { formatMessage } = useIntl();

  const handleRedirect = useCallback((path: string) => {
    const url = `${window.location.origin}${path}`;
    window.location.href = url;
  }, []);

  const MEMBERSHIP_RULES_ITEM = useMemo(
    () => ({
      id: SIDEBAR_ITEMS.MEMBERSHIP_RULES,
      title: formatMessage({ id: 'sidebarMembership.EarningRules' }),
      icon: IconEarningRules,
      path: '/membership/rules'
    }),
    [formatMessage]
  );

  const AUDIENCE_ITEM = useMemo(
    () => ({
      id: SIDEBAR_ITEMS.AUDIENCE_EDITOR,
      title: formatMessage({ id: 'sidebarMembership.AudienceBuilder' }),
      icon: IconUsers,
      path: '/audiences',
      onPageChange() {
        handleRedirect(`/audiences?origin=${name}`);
      }
    }),
    [formatMessage, handleRedirect, name]
  );

  const getItemsToRender = useCallback(() => {
    const items: SidebarItem[] = [MEMBERSHIP_RULES_ITEM];

    userScopes.forEach((currentScope) => {
      const userPrefix = currentScope.split('.')[0];
      if (PREFIXES.includes(userPrefix) && !items.includes(AUDIENCE_ITEM)) {
        items.push(AUDIENCE_ITEM);
      }
    });

    return items;
  }, [userScopes, MEMBERSHIP_RULES_ITEM, AUDIENCE_ITEM]);

  const items = useMemo(
    () => (userScopes.length ? getItemsToRender() : []),
    [userScopes, getItemsToRender]
  );

  useSidebar({ items, utils: [] });

  return items;
};

export default useMembershipScopeSidebar;
