import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

const OriginContext = createContext<string | null>(null);

export const useOrigin = () => useContext(OriginContext);

interface OriginProviderProps {
  children: ReactNode;
}

export const OriginProvider = ({ children }: OriginProviderProps) => {
  const location = useLocation();
  const [origin, setOrigin] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const originParam = searchParams.get('origin');
    if (originParam) {
      setOrigin(originParam);
    }
  }, [location]);

  return <OriginContext.Provider value={origin}>{children}</OriginContext.Provider>;
};
