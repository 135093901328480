import Loader from 'components/Loader/Loader';
import { lazy, Suspense, ReactNode } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import DefaultLayout from './DefaultLayout/DefaultLayout';
import { validOrigins } from 'hooks/useSidebarWithParam/useSidebarWithParam';
import { OriginProvider, useOrigin } from 'context/OriginContext/OriginContext';

const AudiencePage = lazy(() => import('pages/AudiencePage/AudiencePage'));
const DashboardPage = lazy(() => import('pages/DashboardPage/DashboardPage'));
const ExportedFilesPage = lazy(() => import('pages/ExportedFilesPage/ExportedFilesPage'));

interface RequireOriginProps {
  children: ReactNode;
}

const RequireOrigin = ({ children }: RequireOriginProps) => {
  const origin = useOrigin();
  const navigate = useNavigate();

  if (!origin || !validOrigins.includes(origin)) {
    navigate('/error/403', { replace: true });
    return null;
  }

  return <>{children}</>;
};

const Router = () => {
  return (
    <Suspense fallback={<Loader wrapperSize="page" />}>
      <BrowserRouter>
        <OriginProvider>
          <RequireOrigin>
            <Routes>
              <Route path="audiences" element={<DefaultLayout />}>
                <Route index element={<DashboardPage />} />
                <Route path="new-audience" element={<AudiencePage />} />
                <Route path="audience/:id" element={<AudiencePage />} />
                <Route path="exported-files" element={<ExportedFilesPage />} />
              </Route>
            </Routes>
          </RequireOrigin>
        </OriginProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
