import {
  AppHeaderConfig,
  AppHeaderConfigPayload,
  useAppHeader
} from 'admin-portal-shared-services';
import { useEffect } from 'react';

export type UseAppHeaderServiceProps = AppHeaderConfigPayload;
export type UseAppHeaderServiceResponse = {
  appHeaderConfig: AppHeaderConfig;
  setAppHeaderConfig: (config: AppHeaderConfigPayload) => void;
};

const useAppHeaderService = (props?: UseAppHeaderServiceProps): UseAppHeaderServiceResponse => {
  const [appHeaderConfig, setAppHeaderConfig] = useAppHeader();

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle: ' ',
      countrySelect: true,
      vendorSelect: true,
      ...props
    });
  }, [setAppHeaderConfig, props]);

  return { appHeaderConfig, setAppHeaderConfig };
};

export default useAppHeaderService;
